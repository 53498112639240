import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline, keeping } from 'images/koppelingen'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { keepingScreenshot } from 'images/koppelingen'

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'Professionaliseer je urenregistratie',
    description: 'Zorg voor een bijdetijds urenregistratie met de software van Keeping. Houdt uren bij met collega’s of personeel, vul gewerkte uren in op basis van werkzaamheden en zie in een handomdraai gewerkte uren in met overzichtelijke rapportages. Met de uren-app van Keeping is het bijhouden van uren op locatie of onderweg mogelijk, vooral handig wanneer je geen laptop bij de hand hebt. Dit helpt je overal bij het voeren van een nauwkeurige urenadministratie.'
    }, {
      title: 'Salarisadministratie met Employes en Keeping',
      description: 'Koppel je urenregistratie van Keeping met Employes. Maak op het portaal van Keeping een project aan en vul hier het aantal uren in van een oproepkracht. Vervolgens exporteer je alle uren met een druk op de knop naar Employes. Door de automatisering worden alle uren van flexibele medewerkers meteen in het systeem van Employes geschoten en bespaar je zo nog meer tijd en moeite.'
    }, {
      image: keepingScreenshot
    }, {
      title: 'Laat je werknemers zelf de gewerkte uren invullen',
      description: 'Als werkgever wil je de urenregistratie zo simpel mogelijk houden. Voor jezelf, maar ook zeker voor je werknemers. Wanneer je het voor je werknemers zo makkelijk mogelijk maakt om de gewerkte uren zelf bij te kunnen houden, heb je er zelf haast geen omkijken naar. Optimaliseer dit met de overzichtelijke urenregistratie software van Keeping. Zo kunnen werknemers altijd en overal binnen een handomdraai hun gewerkte uren invoeren in een persoonlijk account.'
  }],
  otherClientStories: [{
    name: 'Jolanda',
    functionTitle: 'HR MANAGER, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein'
  }, {
    name: 'Peter',
    functionTitle: 'HR MANAGER, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein'
  }, {
    name: 'Johan',
    functionTitle: 'HR MANAGER, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein'
  }]
}

class Keeping extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Keeping & Employes | Salarisadministratie</title>
            <meta name="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van Keeping."/>
            <meta itemprop="name" content="Koppeling Keeping & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van Keeping."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling keeping padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Keeping aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline} alt="logo employes"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={keeping} alt="afbeelding keeping"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const { title, description } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                <p>{ description}</p>
                              </div>
                            ),
                            image: (
                              <img src={section.image} alt="section" />
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Keeping.propTypes = {
  data: PropTypes.object
}

export default Keeping
